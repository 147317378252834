<template>
  <tw-layout-section id="cookie-declaration">
    <tw-layout-single-column size="l">
      <script-wrap
        id="CookieDeclaration"
        :src="`https://consent.cookiebot.com/${cookiebotId}/cd.js`"
      />
    </tw-layout-single-column>
  </tw-layout-section>
</template>

<script setup>
const cookiebotId = 'bf94f098-f29b-4bd7-af97-5894a2bc3c64';
</script>
