<template>
  <div ref="scriptWrap" />
</template>

<script setup>
import { onMounted, onUnmounted, ref, useAttrs } from 'vue';

const attrs = useAttrs();
const scriptWrap = ref(null);

onMounted(() => {
  const script = document.createElement('script');
  script.type = 'text/javascript';

  Object.keys(attrs).forEach(key => {
    script.setAttribute(key, attrs[key]);
  });

  scriptWrap.value.appendChild(script);
});

onUnmounted(() => {
  scriptWrap.value.removeChild(scriptWrap.value.lastChild);
});
</script>
